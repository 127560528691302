// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-index-js": () => import("./../../src/pages/about/index.js" /* webpackChunkName: "component---src-pages-about-index-js" */),
  "component---src-pages-apply-index-js": () => import("./../../src/pages/apply/index.js" /* webpackChunkName: "component---src-pages-apply-index-js" */),
  "component---src-pages-child-agreement-index-js": () => import("./../../src/pages/childAgreement/index.js" /* webpackChunkName: "component---src-pages-child-agreement-index-js" */),
  "component---src-pages-contact-index-js": () => import("./../../src/pages/contact/index.js" /* webpackChunkName: "component---src-pages-contact-index-js" */),
  "component---src-pages-cooperation-index-js": () => import("./../../src/pages/cooperation/index.js" /* webpackChunkName: "component---src-pages-cooperation-index-js" */),
  "component---src-pages-culture-index-js": () => import("./../../src/pages/culture/index.js" /* webpackChunkName: "component---src-pages-culture-index-js" */),
  "component---src-pages-founder-index-js": () => import("./../../src/pages/founder/index.js" /* webpackChunkName: "component---src-pages-founder-index-js" */),
  "component---src-pages-founders-index-js": () => import("./../../src/pages/founders/index.js" /* webpackChunkName: "component---src-pages-founders-index-js" */),
  "component---src-pages-hire-index-js": () => import("./../../src/pages/hire/index.js" /* webpackChunkName: "component---src-pages-hire-index-js" */),
  "component---src-pages-index-js": () => import("./../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-intro-index-js": () => import("./../../src/pages/intro/index.js" /* webpackChunkName: "component---src-pages-intro-index-js" */),
  "component---src-pages-join-index-js": () => import("./../../src/pages/join/index.js" /* webpackChunkName: "component---src-pages-join-index-js" */),
  "component---src-pages-ketizu-about-index-js": () => import("./../../src/pages/ketizu/about/index.js" /* webpackChunkName: "component---src-pages-ketizu-about-index-js" */),
  "component---src-pages-ketizu-index-js": () => import("./../../src/pages/ketizu/index.js" /* webpackChunkName: "component---src-pages-ketizu-index-js" */),
  "component---src-pages-ketizu-research-index-js": () => import("./../../src/pages/ketizu/research/index.js" /* webpackChunkName: "component---src-pages-ketizu-research-index-js" */),
  "component---src-pages-partner-index-js": () => import("./../../src/pages/partner/index.js" /* webpackChunkName: "component---src-pages-partner-index-js" */),
  "component---src-pages-secret-agreement-index-js": () => import("./../../src/pages/secretAgreement/index.js" /* webpackChunkName: "component---src-pages-secret-agreement-index-js" */),
  "component---src-pages-service-index-js": () => import("./../../src/pages/service/index.js" /* webpackChunkName: "component---src-pages-service-index-js" */),
  "component---src-pages-space-index-js": () => import("./../../src/pages/space/index.js" /* webpackChunkName: "component---src-pages-space-index-js" */),
  "component---src-pages-user-agreement-index-js": () => import("./../../src/pages/userAgreement/index.js" /* webpackChunkName: "component---src-pages-user-agreement-index-js" */),
  "component---src-pages-videos-index-js": () => import("./../../src/pages/videos/index.js" /* webpackChunkName: "component---src-pages-videos-index-js" */),
  "component---src-pages-welcome-index-js": () => import("./../../src/pages/welcome/index.js" /* webpackChunkName: "component---src-pages-welcome-index-js" */),
  "component---src-templates-article-js": () => import("./../../src/templates/article.js" /* webpackChunkName: "component---src-templates-article-js" */),
  "component---src-templates-news-index-js": () => import("./../../src/templates/News/index.js" /* webpackChunkName: "component---src-templates-news-index-js" */)
}

